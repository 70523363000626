import React, { useState } from 'react';
import { UPLOAD_NMAP_FILE } from './NetSpaceConstant';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

const NmapFileUpload = ({runName, networkName}) => {
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const [uploading, setUploading] = useState(false);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const validTypes = ['text/xml', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'];
            if (validTypes.includes(file.type) || file.name.endsWith('.csv')) {
                setError(null);
                handleUpload(file);
            } else {
                setError('Please select a valid XML, XLSX, or CSV file.');
            }
        }
    };

    const handleUpload = async (selectedFile) => {
        if (selectedFile) {
            try {
                setUploading(true);
                const runObj = {
                    runName: runName,
                    networkName: networkName,
                };
                const formData = new FormData();
                formData.append('payload', JSON.stringify(runObj));
                formData.append('file', selectedFile);
                dispatch({
                    type: UPLOAD_NMAP_FILE,
                    payload: {
                        formData: formData,
                    },
                });
            } catch (error) {
                console.error(error);
            } finally {
                setUploading(false);
            }
        }
    };

    return (
        <>
            <input 
                type="file" 
                accept=".xml, .xlsx, .csv" 
                onChange={handleFileChange} 
                disabled={isEmpty(runName)}
            />
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </>
    );
};

export default NmapFileUpload;
