import { call, put, takeEvery, takeLatest, delay, cancel, fork, take } from "redux-saga/effects";
import { getAxiosHeaders, getService } from "../../library/RestAPI";
import {
  UPLOAD_NMAP_FILE,
  SET_NMAP_LOADER,
  NMAP_FILE_UPLOAD,
  NMAP_UPLOAD_UNSUCCESSFUL,
  SET_NETSPACE_LOADER,
  SET_NETSPACE_SNACKBAR,
  GET_HOST_WITH_ALERTS,
  HOST_WITH_ALERT_URL,
  SET_HOST_WITH_ALERT,
  GET_SECURITY_ALERTS,
  SECURITY_ALERTS_URL,
  SET_SECURITY_ALERTS,
  GET_VISIBILITY,
  VISIBILITY_URL,
  SET_VISIBILITY,
  GET_CRITICALITY,
  SET_CRITICALITY,
  CRITICALITY_URL,
  GROUPS_URL,
  SET_GROUPS,
  GET_GROUP_BY,
  UPDATE_SELECTED_GROUP,
  GROUP_COUNT_URL,
  SET_GROUP_COUNT,
  SET_SELECTED_GROUP,
  GET_GROUP_BY_DATA,
  formFinalURL,
  SET_ACCORDION_DATA,
  UNGROUPED,
  SAVE_TAGS,
  SAVE_TAGS_URL,
  CHANGE_TAG_MODAL_STATUS,
  GET_ACTIVE_NETMAP,
  GET_NETMAP_STATUS,
  GET_ACTIVE_NETMAP_URL,
  GET_NETMAP_STATUS_URL,
  SET_NETMAP_PROGRESS,
  FETCH_FILE_STATUS_CANCEL,
  SET_ACTIVE_NETMAP,
  SET_FILE_EDITABLE,
  FILE_COMPLETED_STATUS,
  SET_RELOAD_NETSPACE,
} from "./NetSpaceConstant";
import { isFileProcessCompleted } from "./NetSpaceUtils";
import { get, set } from "lodash";

function* uploadNmapFile(action) {
  yield put({ type: SET_NETSPACE_LOADER, payload: true });
  try {
    const res = yield getService({
      method: "POST",
      url: NMAP_FILE_UPLOAD,
      data: action.payload.formData,
      headers: getAxiosHeaders(true),
    });
    if (res && res.status === 200) {
      yield put({ type: SET_NETSPACE_LOADER, payload: false });
      yield put({ type: SET_FILE_EDITABLE, payload: false });
      yield call(getNmapStatus);
    } else {
      const error = res.response && res.response.data;
      yield put({ type: SET_NETSPACE_LOADER, payload: false });
      yield put({
        type: SET_NETSPACE_SNACKBAR,
        payload: { status: true, message: error.errorMessage },
      });
    }
  } catch (e) {
    yield put({ type: SET_NETSPACE_LOADER, payload: false });
    yield put({
      type: SET_NETSPACE_SNACKBAR,
      payload: { status: true, message: NMAP_UPLOAD_UNSUCCESSFUL },
    });
  }
}

function* getHostWithAlerts() {
  yield put({ type: SET_NETSPACE_LOADER, payload: { key: GET_HOST_WITH_ALERTS, value: true } });
  try {
    const res = yield getService({
      method: "GET",
      url: HOST_WITH_ALERT_URL,
      headers: getAxiosHeaders(true),
    });
    if (res && res.status === 200 && res.data) {
      yield put({ type: SET_NETSPACE_LOADER, payload: { key: GET_HOST_WITH_ALERTS, value: false } });
      yield put({
        type: SET_HOST_WITH_ALERT,
        payload: { hostWithAlert: res.data },
      });
    } else {
      const error = res.response && res.response.data;
      yield put({ type: SET_NETSPACE_LOADER, payload: { key: GET_HOST_WITH_ALERTS, value: false } });
    }
  } catch (e) {
    yield put({ type: SET_NETSPACE_LOADER, payload: { key: GET_HOST_WITH_ALERTS, value: false } });
  }
}

function* getSecurityAlerts() {
  yield put({ type: SET_NETSPACE_LOADER, payload: { key: GET_SECURITY_ALERTS, value: true } });
  try {
    const res = yield getService({
      method: "GET",
      url: SECURITY_ALERTS_URL,
      headers: getAxiosHeaders(true),
    });
    if (res && res.status === 200 && res.data) {
      yield put({ type: SET_NETSPACE_LOADER, payload: { key: GET_SECURITY_ALERTS, value: false } });
      yield put({
        type: SET_SECURITY_ALERTS,
        payload: { securityAlerts: res.data },
      });
    } else {
      const error = res.response && res.response.data;
      yield put({ type: SET_NETSPACE_LOADER, payload: { key: GET_SECURITY_ALERTS, value: false } });
    }
  } catch (e) {
    yield put({ type: SET_NETSPACE_LOADER, payload: { key: GET_SECURITY_ALERTS, value: false } });
  }
}

function* getVisibility() {
  yield put({ type: SET_NETSPACE_LOADER, payload: { key: GET_VISIBILITY, value: true } });
  try {
    const res = yield getService({
      method: "GET",
      url: VISIBILITY_URL,
      headers: getAxiosHeaders(true),
    });
    if (res && res.status === 200 && res.data) {
      yield put({ type: SET_NETSPACE_LOADER, payload: { key: GET_VISIBILITY, value: false } });
      yield put({
        type: SET_VISIBILITY,
        payload: { visibility: res.data },
      });
    } else {
      const error = res.response && res.response.data;
      yield put({ type: SET_NETSPACE_LOADER, payload: { key: GET_VISIBILITY, value: false } });
    }
  } catch (e) {
    yield put({ type: SET_NETSPACE_LOADER, payload: { key: GET_VISIBILITY, value: false } });
  }
}

function* getCriticality() {
  yield put({ type: SET_NETSPACE_LOADER, payload: { key: GET_CRITICALITY, value: true } });
  try {
    const res = yield getService({
      method: "GET",
      url: CRITICALITY_URL,
      headers: getAxiosHeaders(true),
    });
    if (res && res.status === 200 && res.data) {
      yield put({ type: SET_NETSPACE_LOADER, payload: { key: GET_CRITICALITY, value: false } });
      yield put({
        type: SET_CRITICALITY,
        payload: { criticality: res.data },
      });
    } else {
      const error = res.response && res.response.data;
      yield put({ type: SET_NETSPACE_LOADER, payload: { key: GET_CRITICALITY, value: false } });
    }
  } catch (e) {
    yield put({ type: SET_NETSPACE_LOADER, payload: { key: GET_CRITICALITY, value: false } });
  }
}

function* getGroups() {
  yield put({ type: SET_NETSPACE_LOADER, payload: true });
  try {
    const res = yield getService({
      method: "GET",
      url: GROUPS_URL,
      headers: getAxiosHeaders(true),
    });
    if (res && res.status === 200 && res.data) {
      yield put({ type: SET_NETSPACE_LOADER, payload: false });
      yield put({
        type: SET_GROUPS,
        payload: { groups: res.data },
      });
    } else {
      const error = res.response && res.response.data;
      yield put({ type: SET_NETSPACE_LOADER, payload: false });
    }
  } catch (e) {
    yield put({ type: SET_NETSPACE_LOADER, payload: false });
  }
}

function* setSelectedGroup(action) {
  yield put({ type: UPDATE_SELECTED_GROUP, payload: action.payload });
  try {
    const res = yield getService({
      method: "GET",
      url: GROUP_COUNT_URL[action.payload],
      headers: getAxiosHeaders(true),
    });
    if (res && res.status === 200 && res.data) {
      yield put({ type: SET_NETSPACE_LOADER, payload: false });
      yield put({
        type: SET_GROUP_COUNT,
        payload: { groupCount: res.data },
      });
    } else {
      const error = res.response && res.response.data;
      yield put({ type: SET_NETSPACE_LOADER, payload: false });
    }
  } catch (e) {
    yield put({ type: SET_NETSPACE_LOADER, payload: false });
  }
}

function* getGroupData(action) {
  yield put({ type: SET_NETSPACE_LOADER, payload: true });
  try {
    const res = yield getService({
      method: "GET",
      url: formFinalURL(action.payload.key, action.payload.value, action.payload.pageNo, action.payload.pageSize),
      headers: getAxiosHeaders(true),
    });
    if (res && res.status === 200 && res.data) {
      yield put({ type: SET_NETSPACE_LOADER, payload: false });
      yield put({
        type: SET_ACCORDION_DATA,
        payload: { accordionData: res.data, accordionKey: action.payload.value },
      });
    } else {
      const error = res.response && res.response.data;
      yield put({ type: SET_NETSPACE_LOADER, payload: false });
    }
  } catch (e) {
    yield put({ type: SET_NETSPACE_LOADER, payload: false });
  }
}

function* saveTags(action) {
  yield put({ type: SET_NETSPACE_LOADER, payload: true });
  try {
    const res = yield getService({
      method: "POST",
      url: SAVE_TAGS_URL + action.payload.hostId,
      headers: getAxiosHeaders(true),
      data: action.payload.tags
    });
    if (res && res.status === 200) {
      yield put({ type: CHANGE_TAG_MODAL_STATUS, payload: { isTagModalOpen: false, selectedTagData: null, selectedTagNodeId: null } });
      yield call(getGroupData, { payload: action.payload.loadGroupData });
    } else {
      const error = res.response && res.response.data;
      yield put({ type: SET_NETSPACE_LOADER, payload: false });
    }
  } catch (e) {
    yield put({ type: SET_NETSPACE_LOADER, payload: false });
  }
}

function* getActiveNmap(action) {
  yield put({ type: SET_NETSPACE_LOADER, payload: true });
  try {
    const res = yield getService({
      method: "GET",
      url: GET_ACTIVE_NETMAP_URL,
      headers: getAxiosHeaders(true),
    });
    if (res && res.status === 200 && res.data) {
      yield put({ type: SET_NETSPACE_LOADER, payload: false });
      yield put({
        type: SET_ACTIVE_NETMAP,
        payload: { active_nmap: res.data },
      });
    } else {
      const error = res.response && res.response.data;
      yield put({ type: SET_NETSPACE_LOADER, payload: false });
    }
  } catch (e) {
    yield put({ type: SET_NETSPACE_LOADER, payload: false });
  }
}

function* getNmapStatus() {
  try {
    let previousStatus = null;
    while (true) {
      const res = yield getService({
        method: "GET",
        url: GET_NETMAP_STATUS_URL,
        headers: getAxiosHeaders(true),
      });
      if (res && res.status === 200 && res.data) {
        yield put({
          type: SET_NETMAP_PROGRESS,
          payload: { nmap_progress_data: res.data },
        });
        if (previousStatus && res?.data?.status?.code !== previousStatus && !FILE_COMPLETED_STATUS.includes(previousStatus) && FILE_COMPLETED_STATUS.includes(res?.data?.status?.code)) {
          yield put({
            type: SET_RELOAD_NETSPACE,
            payload: true,
          });
        }
        if (FILE_COMPLETED_STATUS.includes(res?.data?.status?.code)) {
          break;
        }
        previousStatus = res?.data?.status?.code;
      } else {
        console.log('getActiveNetMap err=')
        console.log(res)
      }
      yield delay(5000);
    }
  } catch (e) {
    console.log('getActiveNetMap exception=')
    console.log(e)
  }
}

function* watchFetchFileStatus() {
  const task = yield fork(getNmapStatus);
  yield take(FETCH_FILE_STATUS_CANCEL);
  yield cancel(task);
}

function* NetSpaceSaga() {
  yield takeEvery(UPLOAD_NMAP_FILE, uploadNmapFile);
  yield takeEvery(GET_HOST_WITH_ALERTS, getHostWithAlerts);
  yield takeEvery(GET_SECURITY_ALERTS, getSecurityAlerts);
  yield takeEvery(GET_VISIBILITY, getVisibility);
  yield takeEvery(GET_CRITICALITY, getCriticality);
  yield takeEvery(GET_GROUP_BY, getGroups)
  yield takeEvery(SET_SELECTED_GROUP, setSelectedGroup)
  yield takeEvery(GET_GROUP_BY_DATA, getGroupData)
  yield takeEvery(SAVE_TAGS, saveTags)
  yield takeLatest(GET_NETMAP_STATUS, watchFetchFileStatus)
  yield takeLatest(GET_ACTIVE_NETMAP, getActiveNmap)
}
export default NetSpaceSaga;